import { computed, onMounted, ref } from 'vue';
import { getPriceByTotalUser } from '@/ui/helpers/payment/payment-helper';
// import { discountByTotalUserRanges } from '@/ui/helpers/payment/payment.mock';
import { PlanInfo } from '@/application/types/plan.types';
import {
  DiscountByPeriod,
  DiscountByUser,
} from '@/application/types/payment-configs.types';
import paymentStore from '@/store/payment';
import planStore from '@/store/plans';

export default function paymentCalculatePriceComposables(planId) {
  const _paymentStore = paymentStore();
  const _planStore = planStore();

  onMounted(() => {
    billingPeriodId.value = discountByPeriodByPlanId.value?.at(-1)?.id || 1;
  });
  const allPlanByIds = computed(() => _planStore.allPlanByIds);

  const paymentConfigs = computed(() => _paymentStore.paymentConfigs);

  const discountByPeriodByPlanId = computed<DiscountByPeriod[] | []>(() => {
    return (
      paymentConfigs.value?.discountByPeriods?.filter(
        (period) => period?.planId == planId && period?.isActive
      ) || []
    ).slice(-3);
  });
  const discountByUsers = computed<DiscountByUser[]>(
    () =>
      paymentConfigs.value?.discountByUsers?.filter(
        (period) => period?.planId == planId
      ) || []
  );
  const currentPlan = computed<PlanInfo>(() => {
    if (!planId) return {};
    return allPlanByIds.value[planId];
  });
  const basePrice = computed(() => {
    if (!planId) return 0;
    return currentPlan.value?.basePrice;
  });

  const billingPeriodId = ref<number>(1);
  const billingPeriodInfo = computed<DiscountByPeriod>(() => {
    return (paymentConfigs.value?.discountByPeriods?.find(
      (o) => o.id == billingPeriodId.value
    ) || {}) as DiscountByPeriod;
  });

  // new

  const totalUsers = ref(5);
  const calculatedPaymentInfo = computed<{
    monthPrice: any;
    unitPrice: number;
    discountPercent: number;
  }>(() => {
    return getPriceByTotalUser(
      totalUsers.value,
      basePrice.value,
      discountByUsers.value
    );
  });

  const lastTotalAmount = computed(() => {
    return (
      calculatedPaymentInfo.value?.monthPrice *
      billingPeriodInfo.value?.period *
      (1 - billingPeriodInfo.value.discount)
    );
  });

  const priceActualForOneUserInOneMonth = computed<number>(() => {
    return totalUsers.value > 0
      ? lastTotalAmount.value /
          totalUsers.value /
          billingPeriodInfo.value?.period
      : 0;
  });

  const lastTotalAmountNotDiscount = computed(() => {
    return basePrice.value * totalUsers.value * billingPeriodInfo.value.period;
  });

  const discountPercent = computed(() => {
    const notDiscountPrice = lastTotalAmountNotDiscount.value;

    const percent =
      ((notDiscountPrice - lastTotalAmount.value) / notDiscountPrice) * 100;
    if (percent == 0) return 0;
    return percent < 1
      ? Math.floor((percent + Number.EPSILON) * 10) / 10
      : Math.floor(percent);
  });

  return {
    totalUsers,
    billingPeriodId,
    billingPeriodInfo,
    lastTotalAmount,
    lastTotalAmountNotDiscount,
    discountPercent,
    priceActualForOneUserInOneMonth,
    discountByPeriodByPlanId,
  };
}
