export const WARNING_LEVEL = {
  L1: {
    background: '#E0F1F2',
    text: '#FB923C',
  },
  L1_5: {
    background: '#076260',
    text: '#B1DCDE',
  },
  L2: {
    background: '#FFEDD5',
    text: '#F97316',
  },
  L2_5: {
    background: '#FB923C',
    text: '#FFF',
  },
  L3: {
    background: '#F87171',
    text: '#FFF',
  },
  L3_5: {
    background: '#DC2626',
    text: '#FFF',
  },
};

const image_url_base = `${process.env.VUE_APP_AWS_S3_SOURCE_DEFAULT}/plan-state`;

export const C_MESSAGE_CODE = {
  M1: {
    title: 'PLAN_NOTIFICATION_MESSAGE_M1_TITLE',
    titleWithBonus: 'PLAN_NOTIFICATION_MESSAGE_M1_TITLE_HAS_BONUS',
    body: 'PLAN_NOTIFICATION_MESSAGE_M1_CONTENT',
    shortContent: 'PLAN_NOTIFICATION_MESSAGE_M1_SHORT_CONTENT',
    hasPopup: false,
    disableClose: false,
    popupTitle: '',
    popupBody: '',
    iconName: 'info',
    imageUrl: '',
    lockFunction: {
      warningMessage: 'PLAN_NOTIFICATION_MESSAGE_M1_LOCK_FUNCTION_WARNING',
      blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M1_LOCK_FUNCTION_BLOCK',
    },
  },
  M2: {
    title: 'PLAN_NOTIFICATION_MESSAGE_M2_TITLE',
    titleWithBonus: '',
    shortContent: 'PLAN_NOTIFICATION_MESSAGE_M2_SHORT_CONTENT',
    body: 'PLAN_NOTIFICATION_MESSAGE_M2_CONTENT',
    hasPopup: true,
    disableClose: false,
    popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M2_MODAL_TITLE',
    popupBody: 'PLAN_NOTIFICATION_MESSAGE_M2_MODAL_BODY',
    iconName: 'warning',
    imageUrl: `${image_url_base}/plan-warning-m2.png`,
    lockFunction: {
      warningMessage: 'PLAN_NOTIFICATION_MESSAGE_M2_LOCK_FUNCTION_WARNING',
      blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M2_LOCK_FUNCTION_BLOCK',
    },
  },
  M3: {
    title: 'PLAN_NOTIFICATION_MESSAGE_M3_TITLE',
    titleWithBonus: '',
    shortContent: 'PLAN_NOTIFICATION_MESSAGE_M3_SHORT_CONTENT',
    body: 'PLAN_NOTIFICATION_MESSAGE_M3_CONTENT',
    hasPopup: false,
    disableClose: false,
    popupTitle: '',
    popupBody: '',
    iconName: 'info',
    imageUrl: '',
    lockFunction: {
      warningMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_WARNING',
      blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M3_LOCK_FUNCTION_BLOCK',
    },
  },
  M4: {
    title: 'PLAN_NOTIFICATION_MESSAGE_M4_TITLE',
    titleWithBonus: '',
    shortContent: 'PLAN_NOTIFICATION_MESSAGE_M4_SHORT_CONTENT',
    body: 'PLAN_NOTIFICATION_MESSAGE_M4_CONTENT',
    hasPopup: true,
    disableClose: false,
    popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M4_MODAL_TITLE',
    popupBody: 'PLAN_NOTIFICATION_MESSAGE_M4_MODAL_BODY',
    iconName: 'info',
    imageUrl: `${image_url_base}/plan-warning-m4.png`,
    lockFunction: {
      warningMessage: 'PLAN_NOTIFICATION_MESSAGE_M4_LOCK_FUNCTION_WARNING',
      blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M4_LOCK_FUNCTION_BLOCK',
    },
  },
  M5: {
    title: 'PLAN_NOTIFICATION_MESSAGE_M5_TITLE',
    titleWithBonus: '',
    shortContent: 'PLAN_NOTIFICATION_MESSAGE_M5_SHORT_CONTENT',
    body: 'PLAN_NOTIFICATION_MESSAGE_M5_MODAL_BODY',
    hasPopup: true,
    disableClose: true,
    popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M5_MODAL_TITLE',
    popupBody: 'PLAN_NOTIFICATION_MESSAGE_M5_MODAL_BODY',
    iconName: 'warning',
    imageUrl: `${image_url_base}/plan-warning-m5.png`,
    lockFunction: {
      warningMessage: 'PLAN_NOTIFICATION_MESSAGE_M5_LOCK_FUNCTION_WARNING',
      blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M5_LOCK_FUNCTION_BLOCK',
    },
  },
  M6: {
    title: 'PLAN_NOTIFICATION_MESSAGE_M6_TITLE',
    titleWithBonus: '',
    shortContent: 'PLAN_NOTIFICATION_MESSAGE_M6_SHORT_CONTENT',
    body: 'PLAN_NOTIFICATION_MESSAGE_M6_MODAL_BODY',
    hasPopup: true,
    disableClose: true,
    popupTitle: 'PLAN_NOTIFICATION_MESSAGE_M6_MODAL_TITLE',
    popupBody: 'PLAN_NOTIFICATION_MESSAGE_M6_MODAL_BODY',
    iconName: 'warning',
    imageUrl: `${image_url_base}/plan-warning-m6.png`,
    lockFunction: {
      warningMessage: 'PLAN_NOTIFICATION_MESSAGE_M6_LOCK_FUNCTION_WARNING',
      blockMessage: 'PLAN_NOTIFICATION_MESSAGE_M6_LOCK_FUNCTION_BLOCK',
    },
  },
};

export enum EPlanAction {
  PURCHASE = 'PURCHASE',
  GET_MORE = 'GET_MORE',
  EXTEND = 'EXTEND',
  RENEW = 'RENEW',
  CONTACT = 'CONTACT',
}

const PURPOSE_PAYMENT_MESSAGE = {
  M1: 'PURPOSE_PAYMENT_MESSAGE_M1',
  M2: 'PURPOSE_PAYMENT_MESSAGE_M2',
  M3: 'PURPOSE_PAYMENT_MESSAGE_M3',
  M4: 'PURPOSE_PAYMENT_MESSAGE_M4',
};

export const UI_BY_PLAN_STATE = {
  PAID: {
    background: '#00b0b8',
    text: '#fff',
    name: 'Paid',
    code: 'PAID',
    nameCode: 'PLAN_STATE_LABEL_PAID',
    purposeMessage: PURPOSE_PAYMENT_MESSAGE.M2,
    purposeBuyMoreMessage: PURPOSE_PAYMENT_MESSAGE.M4,
    DEFAULT: {
      warningUI: null,
      message: null,

      action: EPlanAction.EXTEND,
      actionLabelCode: 'PAYMENT_EXTEND',
    },
    INCOMING_EXPIRED: {
      warningUI: WARNING_LEVEL.L2,
      message: C_MESSAGE_CODE.M3,

      action: EPlanAction.EXTEND,
      actionLabelCode: 'PAYMENT_EXTEND',
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: WARNING_LEVEL.L2,
      message: C_MESSAGE_CODE.M3,

      action: EPlanAction.EXTEND,
      actionLabelCode: 'PAYMENT_EXTEND',
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
        shortMessage: '',
      },
    },
  },
  TRIAL: {
    background: '#fed7aa',
    text: '#ea580c',
    name: 'Trial',
    code: 'TRIAL',
    nameCode: 'PLAN_STATE_LABEL_TRIAL',
    purposeMessage: PURPOSE_PAYMENT_MESSAGE.M1,
    DEFAULT: {
      warningUI: WARNING_LEVEL.L1,
      message: C_MESSAGE_CODE.M1,
      action: EPlanAction.PURCHASE,
      actionLabelCode: 'PAYMENT_LABEL_PURCHASE',
    },
    INCOMING_EXPIRED: {
      warningUI: WARNING_LEVEL.L2,
      message: C_MESSAGE_CODE.M1,

      action: EPlanAction.PURCHASE,
      actionLabelCode: 'PAYMENT_LABEL_PURCHASE',
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: WARNING_LEVEL.L2,
      message: C_MESSAGE_CODE.M1,

      action: EPlanAction.PURCHASE,
      actionLabelCode: 'PAYMENT_LABEL_PURCHASE',
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
        shortMessage: '',
      },
    },
  },
  INIT: {
    background: '#dcfce7',
    text: '#076260',
    name: 'Decide payment',
    code: 'INIT',
    nameCode: 'PLAN_STATE_LABEL_DECIDE_PAYMENT',
    purposeMessage: PURPOSE_PAYMENT_MESSAGE.M1,
    DEFAULT: {
      warningUI: null,
      message: null,

      action: null,
      actionLabelCode: null,
    },
    INCOMING_EXPIRED: {
      warningUI: null,
      message: null,

      action: null,
      actionLabelCode: null,
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: null,
      message: null,

      action: null,
      actionLabelCode: null,
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
        shortMessage: '',
      },
    },
  },
  OVERDUE_TRIAL: {
    background: '#ea580c',
    text: '#fff',
    name: 'Overdue trial',
    code: 'OVERDUE_TRIAL',
    nameCode: 'PLAN_STATE_LABEL_OVERDUE_TRIAL',
    purposeMessage: PURPOSE_PAYMENT_MESSAGE.M1,
    DEFAULT: {
      warningUI: WARNING_LEVEL.L2_5,
      message: C_MESSAGE_CODE.M2,
      action: EPlanAction.PURCHASE,
      actionLabelCode: 'PAYMENT_LABEL_PURCHASE',
    },
    INCOMING_EXPIRED: {
      warningUI: WARNING_LEVEL.L2_5,
      message: C_MESSAGE_CODE.M2,
      action: EPlanAction.PURCHASE,
      actionLabelCode: 'PAYMENT_LABEL_PURCHASE',
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: WARNING_LEVEL.L2_5,
      message: C_MESSAGE_CODE.M2,
      action: EPlanAction.PURCHASE,
      actionLabelCode: 'PAYMENT_LABEL_PURCHASE',
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_TRIAL_LONG',
        shortMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_TRIAL_SHORT',
      },
    },
  },
  OVERDUE_PAID: {
    background: '#ea580c',
    text: '#fff',
    name: 'Overdue paid',
    code: 'OVERDUE_PAID',
    nameCode: 'PLAN_STATE_LABEL_OVERDUE_PAID',
    purposeMessage: PURPOSE_PAYMENT_MESSAGE.M3,
    DEFAULT: {
      warningUI: WARNING_LEVEL.L2_5,
      message: C_MESSAGE_CODE.M4,
      action: EPlanAction.RENEW,
      actionLabelCode: 'PAYMENT_RENEW',
    },
    INCOMING_EXPIRED: {
      warningUI: WARNING_LEVEL.L2_5,
      message: C_MESSAGE_CODE.M4,
      action: EPlanAction.RENEW,
      actionLabelCode: 'PAYMENT_RENEW',
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: WARNING_LEVEL.L2_5,
      message: C_MESSAGE_CODE.M4,

      action: EPlanAction.RENEW,
      actionLabelCode: 'PAYMENT_RENEW',
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_PAID_LONG',
        shortMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_PAID_SHORT',
      },
    },
  },
  BLOCK: {
    background: '#ef4444',
    text: '#fff',
    name: 'Block',
    code: 'BLOCK',
    nameCode: 'PLAN_STATE_LABEL_BLOCK',
    purposeMessage: '',
    DEFAULT: {
      warningUI: WARNING_LEVEL.L3,
      message: C_MESSAGE_CODE.M5,

      action: EPlanAction.RENEW,
      actionLabelCode: 'PAYMENT_RENEW',
    },
    INCOMING_EXPIRED: {
      warningUI: WARNING_LEVEL.L3,
      message: C_MESSAGE_CODE.M5,
      action: EPlanAction.RENEW,
      actionLabelCode: 'PAYMENT_RENEW',
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: WARNING_LEVEL.L3,
      message: C_MESSAGE_CODE.M5,
      action: EPlanAction.RENEW,
      actionLabelCode: 'PAYMENT_RENEW',
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
        shortMessage: '',
      },
    },
  },
  DEFINITIVE_BLOCK: {
    background: '#ef4444',
    text: '#fff',
    name: 'DEFINITIVE BLOCK',
    code: 'DEFINITIVE_BLOCK',
    nameCode: 'PLAN_STATE_LABEL_BLOCK',
    purposeMessage: '',
    DEFAULT: {
      warningUI: WARNING_LEVEL.L3_5,
      message: C_MESSAGE_CODE.M6,

      action: EPlanAction.CONTACT,
      actionLabelCode: 'PAYMENT_CONTACT',
    },
    INCOMING_EXPIRED: {
      warningUI: WARNING_LEVEL.L3_5,
      message: C_MESSAGE_CODE.M6,

      action: EPlanAction.CONTACT,
      actionLabelCode: 'PAYMENT_CONTACT',
    },
    INCOMING_EXPIRED_HIGHER: {
      warningUI: WARNING_LEVEL.L3_5,
      message: C_MESSAGE_CODE.M6,

      action: EPlanAction.CONTACT,
      actionLabelCode: 'PAYMENT_CONTACT',
    },
    BLOCK_SCREEN: {
      message: {
        longMessage: 'BLOCK_SCREEN_MESSAGE_OVERDUE_DEFAULT_LONG',
        shortMessage: '',
      },
    },
  },
};

export const UI_BY_PLAN_ID = {
  PRO: {
    STYLE: {
      background: '#00b0b8',
      text: '#fff',
    },
  },
  VIP: {
    STYLE: {
      background: '#008d8d',
      text: '#fff',
    },
  },
};
