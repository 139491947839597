// Generated by https://quicktype.io

import { EPlanId } from '../enums/PlanEnums';

export interface PlanInfo {
  id: string;
  index: number;
  code: string;
  lang: string;
  name: string;
  description: string;
  basePrice: number;
  currency: string;
  market: string;
  isActive: boolean;
  planFeatures: any[];
}

export enum EPlanState {
  INIT = 'INIT',
  PAID = 'PAID',
  TRIAL = 'TRIAL',
  OVERDUE_TRIAL = 'OVERDUE_TRIAL',
  OVERDUE_PAID = 'OVERDUE_PAID',
  BLOCK = 'BLOCK',
}

// Generated by https://quicktype.io

export interface IOrgCurrentPlan {
  organizationId: number;
  startDate: string;
  endDate: string;
  paymentMethod: null;
  billingCycle: null;
  nextBillingDate: null;
  planId: EPlanId;
  members: number;
  status: string;
  paymentStatus: string;
  lastInvoiceDate: null;
  lastInvoiceAmount: null;
  lastPayer: null;
  changedPlanDate: null;
  usageExceeded: boolean;
  stateCode: EPlanState;
  subStateCode: 'DEFAULT' | 'INCOMING_EXPIRED' | 'INCOMING_EXPIRED_HIGHER';
  stateStartDate: string;
  stateEndDate: string;
  lastPayerName: null;
  lastPayerAvatar: null;
  lastPayerAvatarUrl: null;
  remainDays: number;
  period: number;
  isLifeTime: boolean;
  offer: {
    reason: string;
    endDate: string;
    remainDays: number;
    bonusDays: number;
  };
}
