/* eslint-disable no-unused-vars */
export enum StorageConstant {
  LANGUAGE = 'LANGUAGE',
  TOKEN = 'TOKEN',
  LOCATION = 'LOCATION',
  PHONE_COUNTRY_CODE = 'PHONE_COUNTRY_CODE',
  ORGANIZATIONS = 'ORGANIZATIONS',
  ACTIVE_ORG = 'ACTIVE_ORG',
  CURRENT_USER_ID = 'CURRENT_USER_ID',
  DEVICE_TOKEN = 'DEVICE_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  PAYMENT_TOKEN = 'PAYMENT_TOKEN',
  BUY_LICENSE_TOKEN = 'BUY_LICENSE_TOKEN',
  TARGET_APP_URL = 'TARGET_APP_URL',
}

const PERSISTENCE_ITEMS = [
  StorageConstant.LANGUAGE,
  StorageConstant.PHONE_COUNTRY_CODE,
  StorageConstant.LOCATION,
];

export const getLocalStorage = (item) => {
  return localStorage.getItem(item) || '';
};
export const setLocalStorage = (item, value) => {
  localStorage.setItem(item, value);
};
export const removeLocalStorage = (item) => {
  localStorage.removeItem(item);
};

export const clearLocalStorage = ({ isSwitchingOrg }) => {
  for (const item of Object.values(StorageConstant)) {
    if (
      PERSISTENCE_ITEMS.includes(item) ||
      (isSwitchingOrg && item === StorageConstant.ORGANIZATIONS)
    ) {
      continue;
    }

    removeLocalStorage(item);
  }
};

export const getCurrentUserId = () => {
  return parseInt(getLocalStorage(StorageConstant.CURRENT_USER_ID));
};
